import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import GoBillionCoinsGuidelines from "./components/GoBillionCoinsGuidelines/GoBillionCoinsGuidelines";
import Layout from "./components/Layout/Layout";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "./components/TermsAndConditions/TermsAndConditions";
import CancellationPolicy from "./components/CancellationPolicy/CancellationPolicy";
import "./newcss.css";

class App extends Component {
  render() {
    return (
      <div className="App">
        <Router>
          <Switch>
            <Route exact path="/" component={Layout} />
            <Route exact path="/gobillioncoinsguidelines.html" component={GoBillionCoinsGuidelines} />
            <Route exact path="/term.html" component={TermsAndConditions} />
            <Route exact path="/privacy-policy.html" component={PrivacyPolicy} />
            <Route exact path="/cancellation-policy.html" component={CancellationPolicy} />
          </Switch>
         </Router>
      </div>
    );
  }
}

export default App;
