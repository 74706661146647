import React, { Component, Fragment } from "react";
import About from "../../sections/AboutUs/About";
import Explore from "../../sections/Explore/Explore";
import GetInTouch from "../../sections/GetInTouch/GetInTouch";
import Main from "../../sections/Main/Main";
import Outreach from "../../sections/Outreach/Outreach";
import Testimonials from "../../sections/Testimonials/Testimonials";
import Navbar from "../Navbar";

class Layout extends Component {
  render() {
    return (
      <Fragment>
        <Navbar />
        <Main />
        <About />
        <Explore />
        {/* <BecomeSupplier /> */}
        <Testimonials />
        <GetInTouch />
        <Outreach />
      </Fragment>
    );
  }
}

export default Layout;
